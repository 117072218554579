export const state = () => ({
  initiated: false,
  clubs: {},
  tours: {},
  participants: {},
  clubTours: {},
  clubParticipants: {},
});

export const getters = {
  tours(state) {
    return Object.values(state.tours);
  },
  tour(state) {
    return (tourId) => {
      return state.tours[tourId];
    };
  },
  participants(state) {
    return (tourId) => {
      return state.participants[tourId] || [];
    };
  },
  clubTours(state) {
    return Object.values(state.clubTours);
  },
  clubTour(state) {
    return (tourId) => {
      return state.clubTours[tourId];
    };
  },
  clubParticipants(state) {
    return (tourId) => {
      return state.clubParticipants[tourId] || [];
    };
  },
};

export const mutations = {
  setInitiated(state) {
    state.initiated = true;
  },
  setClub(state, payload) {
    state.clubs[payload.clubId] = payload.club;
  },
  setTours(state, payload) {
    const append = {};

    Object.keys(payload.tours).forEach((index) => {
      const tour = payload.tours[index];

      tour.id = tour.nummer;
      tour.clubId = payload.clubId;

      append[tour.nummer] = tour;
    });

    state.tours = Object.assign({}, state.tours, append);
  },
  setClubTours(state, payload) {
    const append = {};

    Object.keys(payload.tours).forEach((index) => {
      const tour = payload.tours[index];

      tour.own = true;
      tour.id = tour.nummer;
      tour.clubId = payload.clubId;
      tour.instellingen = tour.instellingen || {};

      append[tour.nummer] = tour;
    });

    state.clubTours = Object.assign({}, state.clubTours, append);
  },
  updateTourSettings(state, payload) {
    Object.keys(payload.settings).forEach((setting) => {
      state.tours[payload.tourId].instellingen[setting] = payload.settings[setting];
    });
  },
};

export const actions = {
  async init({state, dispatch, commit, rootState}) {
    if (state.initiated) {
      return;
    }

    commit('setInitiated');
    await dispatch('fetchUserTours');

    const interval = setInterval(() => {
      if (!rootState.auth.loggedIn) {
        clearInterval(interval);
        return;
      }

      dispatch('fetchUserTours');
    }, 60000 * 5); // every 5 min
  },
  async fetchUserTours({commit, rootState}) {
    const currentUser = rootState.auth.user;
    const clubIds = currentUser.clubs || [];
    const shouldRefresh = await this.$offline.checkShouldRefresh();

    // Wrap promise to get all success responses
    const wrappedPromise = (promise) => {
      return promise.catch(e => e);
    };

    let promises = [];

    // Fetch club info
    clubIds.forEach((clubId) => {
      promises.push(wrappedPromise(this.$offline.refresh(shouldRefresh).getClub(clubId)));
    });

    const clubsData = await Promise.all(promises);

    clubsData.forEach((club, index) => {
      if (club instanceof Error) {
        return;
      }

      commit('setClub', {
        clubId: clubIds[index],
        club,
      });
    });

    // Fetch general tours
    promises = [];

    clubIds.forEach((clubId) => {
      promises.push(wrappedPromise(this.$offline.refresh(shouldRefresh).getTours(clubId, currentUser.isVolunteer)));
    });

    const listsTours = await Promise.all(promises);

    listsTours.forEach((list, index) => {
      if (!list || list instanceof Error) {
        return;
      }

      commit('setTours', {
        clubId: clubIds[index],
        tours: list,
      });

      // Fetch participants; only save to indexedDB
      list.forEach((tour) => {
        this.$offline.refresh(shouldRefresh).getParticipants(tour.nummer);
        this.$offline.refresh(shouldRefresh).getPreRegistrations(tour.nummer);
      });
    });

    // Fetch club tours
    promises = [];

    clubIds.forEach((clubId) => {
      if (clubId instanceof Error) {
        return;
      }

      promises.push(wrappedPromise(this.$offline.refresh(shouldRefresh).getClubTours(clubId)));
    });

    const listsClubTours = await Promise.all(promises);

    listsClubTours.forEach((list, index) => {
      if (!list || list instanceof Error) {
        return;
      }

      commit('setClubTours', {
        clubId: clubIds[index],
        tours: list,
      });

      // Fetch participants; only save to indexeddb
      list.forEach((tour) => {
        this.$offline.refresh(shouldRefresh).getClubParticipants(tour.nummer);
      });
    });

    if (shouldRefresh) {
      this.$offline.setUpdatedAt();
      this.$offline.gc();
    }
  },
  async reloadClubTours({state, commit}, clubId) {
    const tours = await this.$offline.refresh(true).getClubTours(clubId);

    commit('setClubTours', {
      clubId,
      tours,
    });
  },
};
