import Vue from 'vue';
import {BARCODE_DAGDEELNEMER} from '~/plugins/constants';

export const state = () => ({
  uniqId: '',
  errors: 0,
  lastError: 0,
  queue: {},
  reserved: {},
  processed: {},
});

export const getters = {
  totalScanned(state) {
    let count = 0;

    Object.values(state.queue).forEach((list) => {
      count += Object.values(list).filter((item) => {
        return item.ready;
      }).length;
    });

    return count;
  },
  syncedScanned(state) {
    let count = 0;

    Object.values(state.queue).forEach((list) => {
      Object.values(list).forEach((item) => {
        if (item.processed) {
          count++;
        }
      });
    });

    return count;
  },
  getParticipantsByTour(state) {
    return (tourId, own) => {
      const key = own ? tourId + '_own' : tourId;

      return state.queue[key] || {};
    };
  },
  getParticipant(state) {
    return (tourId, barcode) => {
      if (!state.queue[tourId]) {
        return null;
      }

      return state.queue[tourId][barcode] || null;
    };
  },
};

export const mutations = {
  setInitiated(state) {
    if (!state.initiated) {
      state.initiated = true;

      // Save an identifier to check if current process is the last one that modified the queue
      state.uniqId = Math.random()
        .toString(36)
        .substring(2);
    }
  },
  setQueue(state, queue) {
    state.queue = queue;
  },
  addOrUpdateQueueItem(state, payload) {
    const queueKey = payload.own ? payload.tourId + '_own' : payload.tourId;

    if (!state.queue[queueKey]) {
      const append = {};
      append[queueKey] = {};

      state.queue = Object.assign({}, state.queue, append);
    }

    // Should be processed again
    payload.participant.reserved = false;
    payload.participant.processed = false;

    Vue.set(state.queue[queueKey], payload.barcode, payload.participant);
  },
  markReserved(state, payload) {
    const queueKey = payload.own ? payload.tourId + '_own' : payload.tourId;

    state.queue[queueKey][payload.item.barcode].reserved = true;

    if (!state.reserved[queueKey]) {
      state.reserved[queueKey] = {};
    }

    const clone = o => JSON.parse(JSON.stringify(o));

    const cloned = clone(state.queue[queueKey][payload.item.barcode]);

    delete cloned.ready;
    delete cloned.reserved;
    delete cloned.processed;

    state.reserved[queueKey][payload.item.barcode] = cloned;
  },
  markProcessed(state, payload) {
    const queueKey = payload.own ? payload.tourId + '_own' : payload.tourId;

    state.queue[queueKey][payload.item.barcode].processed = true;

    if (!state.processed[queueKey]) {
      state.processed[queueKey] = {};
    }

    const clone = o => JSON.parse(JSON.stringify(o));

    state.processed[queueKey][payload.item.barcode] = clone(
      state.reserved[queueKey][payload.item.barcode],
    );

    delete state.reserved[queueKey][payload.item.barcode];
  },
  clearProcessed(state, queueKey) {
    delete state.processed[queueKey];

    if (!state.queue[queueKey]) {
      return;
    }

    Object.keys(state.queue[queueKey]).forEach((key) => {
      if (state.queue[queueKey][key].processed) {
        delete state.queue[queueKey][key];
      }
    });
  },
  setError(state) {
    state.errors = state.errors + 1;
    state.lastError = +new Date() / 1000;
  },
  resetErrors(state) {
    state.errors = 0;
    state.lastError = 0;
  },
  clearReserved(state, tourId) {
    delete state.reserved[tourId];
    delete state.queue[tourId];
  },
};

export const actions = {
  async init({state, dispatch, commit}) {
    if (state.initiated) {
      return;
    }

    commit('setInitiated');
    await dispatch('loadQueue');

    setInterval(() => {
      // Skip when not online ($nuxt.isOnline is not available in store)
      if (!navigator.onLine) {
        return;
      }

      const now = +new Date() / 1000;
      const waitSeconds = 60;

      if (state.errors > 5 && state.lastError > now - waitSeconds) {
        return;
      }

      dispatch('process');
    }, 10000);
  },
  async loadQueue({state, commit}) {
    const queue = (await this.$vlf.getItem('queue')) || {};
    commit('setQueue', queue);

    // Mark current session as active queue handler
    await this.$vlf.setItem('queueId', state.uniqId);
  },
  async saveQueue({state, dispatch}) {
    await dispatch('_checkQueueFreshness');
    await this.$vlf.setItem('queue', state.queue);
  },
  async process({state, commit, dispatch}) {
    console.debug('process...');

    Object.keys(state.queue).forEach((tourId) => {
      const list = state.queue[tourId];

      Object.values(list).forEach(async (item) => {
        let shouldMark = false;

        if (item.ready && !item.reserved && !item.processed) {
          shouldMark = true;
        } else if (
          item.reserved &&
          !item.processed &&
          (!(tourId in state.reserved) || !(item.barcode in state.reserved[tourId]))
        ) {
          shouldMark = true;
        }

        if (shouldMark) {
          await commit('markReserved', {
            tourId,
            item,
          });
        }
      });
    });

    await this.$vlf.setItem('reserved', state.reserved);

    await dispatch('sendReserved');
  },
  async sendReserved({state, rootState, commit, dispatch}) {
    const cleanClone = (o) => {
      o = JSON.parse(JSON.stringify(o));

      // Remove internal keys
      delete o.ready;
      delete o.afstanden;

      for (const key in o) {
        if (o.hasOwnProperty(key) && (o[key] === null || o[key] === undefined)) {
          delete o[key];
        }
      }

      return o;
    };

    const reportError = (error) => {
      console.error(error);
      this.$bugsnag.notify(error);

      commit('setError');
    };

    let hasResetQueue = false;

    Object.keys(state.reserved).forEach((tourId) => {
      const list = state.reserved[tourId];
      let tour;
      let own = false; // Boolean for own club tours

      if (!Number.isInteger(tourId) && (tourId + '').endsWith('_own')) {
        tourId = parseInt(tourId.replace('_own', ''), 10);
        tour = rootState.ntfu.clubTours[tourId];
        own = true;
      } else {
        tour = rootState.ntfu.tours[tourId];
      }

      if (!tour) {
        console.error('No tour found for id: ' + tourId);
        this.$bugsnag.notify(new Error(`Deleted scanned participants for tour with id: ${tourId}`));
        commit('clearReserved', tourId);

        hasResetQueue = true;
        return;
      }

      Object.values(list).forEach(async (item) => {
        try {
          let response;

          // Save old barcode to a temporary variable to use it later.
          // If this isn't saved it can't find the barcode later on.
          const {barcode} = item;
          // Temporary barcodes for dagdeelnemers start with a 'd' and are followed by a random set of characters and numbers.
          if (typeof item.barcode === 'string' && item.barcode[0] === 'd') {
            // Temporarily set the barcode to the general dagdeelnemer barcode.
            item.barcode = BARCODE_DAGDEELNEMER;
            item.sng_id = barcode;
          }

          if (own) {
            response = await this.$ntfu.sendClubParticipants(tour.clubId, tourId, [
              cleanClone(item),
            ]);
          } else {
            response = await this.$ntfu.sendParticipants(tour.clubId, tourId, [cleanClone(item)]);
          }

          // Set the barcode back to the original barcode (the temporary one, starting with a 'd')
          if (item.barcode === BARCODE_DAGDEELNEMER) {
            item.barcode = barcode;
          }

          if (!response) {
            return reportError('Unexpected response.');
          }

          if (response.error) {
            return reportError(response.error);
          }

          if (response.aantal === 0) {
            // The API won't accept the same participant being sent more than once.
            // This results in the following response: {"aantal":0,"error":null}
            console.log('Participant already sent: ' + item.barcode);
          }

          commit('resetErrors');

          await commit('markProcessed', {
            clubId: tour.clubId,
            own: tour.own,
            tourId,
            item,
          });

          await dispatch('saveQueue');
        } catch (e) {
          reportError(e);
        }
      });
    });

    if (hasResetQueue) {
      await this.$vlf.setItem('queue', state.queue);
    }

    await this.$vlf.setItem('reserved', state.reserved);
    await this.$vlf.setItem('processed', state.processed);
  },
  async addOrUpdateParticipant({commit, dispatch}, payload) {
    await dispatch('_checkQueueFreshness');
    await commit('addOrUpdateQueueItem', payload);
    await dispatch('saveQueue');
  },
  async clearProcessed({commit, dispatch}, queueKey) {
    await commit('clearProcessed', queueKey);
    await dispatch('saveQueue');
  },
  async _checkQueueFreshness({dispatch}) {
    const isOk = await dispatch('_checkUniqId');

    if (!isOk) {
      await dispatch('loadQueue');
    }
  },
  async _checkUniqId({state}) {
    return state.uniqId === (await this.$vlf.getItem('queueId'));
  },
};
