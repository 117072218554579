import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import * as localForage from 'localforage';

const bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY,
  appVersion: process.env.RELEASE,
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: ['production'],
  beforeSend: async (report) => {
    await localForage.config({storeName: 'ntfu_toertochten'});
    if (process.client) {
      await localForage.setDriver([localForage.INDEXEDDB]);
    }
    report.updateMetaData('queue data', {
      queue: await localForage.getItem('queue'),
      reserved: await localForage.getItem('reserved'),
      processed: await localForage.getItem('processed'),
    });
  },
});

bugsnagClient.use(bugsnagVue, Vue);

Vue.prototype.$bugsnag = bugsnagClient;

export default ({store}, inject) => {
  if (store.state.release) {
    bugsnagClient.app.version = store.state.release;
  }

  inject('bugsnag', bugsnagClient);
};
