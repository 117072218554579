import Vue from 'vue';
import {DateTime, Settings} from 'luxon';

Vue.prototype.$datetime = DateTime;

export default ({app}, inject) => {
  Settings.defaultLocale = app.head.htmlAttrs.lang;

  inject('datetime', DateTime);
};

const toLuxon = (raw) => {
  if (raw && raw[0] === '/' && raw.endsWith('/')) {
    const parts = raw.split('+');
    const date = Number(parts[0].replace(/[^0-9]/g, ''));
    const timezone = parts[1].split('');

    return DateTime.fromMillis(date).plus({
      // Correction for timezone
      minutes: timezone[0] * 600 + timezone[1] * 60 + timezone[2] * 10 + timezone[3] * 1,
    });
  }

  return DateTime.fromISO(raw);
};

Vue.filter('luxon', (val, format) => {
  if (!format) {
    return toLuxon(val);
  }

  return toLuxon(val).toFormat(format);
});
