import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b70a32f0 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _4ca8b228 = () => interopDefault(import('../pages/toertochten/index.vue' /* webpackChunkName: "pages/toertochten/index" */))
const _7021dd96 = () => interopDefault(import('../pages/toertochten/create.vue' /* webpackChunkName: "pages/toertochten/create" */))
const _45fc9914 = () => interopDefault(import('../pages/club/_slug/index.vue' /* webpackChunkName: "pages/club/_slug/index" */))
const _e333213a = () => interopDefault(import('../pages/toertochten/_slug/index.vue' /* webpackChunkName: "pages/toertochten/_slug/index" */))
const _839e1ffe = () => interopDefault(import('../pages/club/_slug/afstandenkaart.vue' /* webpackChunkName: "pages/club/_slug/afstandenkaart" */))
const _143fb926 = () => interopDefault(import('../pages/club/_slug/deelnemers.vue' /* webpackChunkName: "pages/club/_slug/deelnemers" */))
const _b9bc7adc = () => interopDefault(import('../pages/toertochten/_slug/afstandenkaart.vue' /* webpackChunkName: "pages/toertochten/_slug/afstandenkaart" */))
const _46226393 = () => interopDefault(import('../pages/toertochten/_slug/dagdeelnemers.vue' /* webpackChunkName: "pages/toertochten/_slug/dagdeelnemers" */))
const _595a7c37 = () => interopDefault(import('../pages/toertochten/_slug/deelnemers.vue' /* webpackChunkName: "pages/toertochten/_slug/deelnemers" */))
const _98c04860 = () => interopDefault(import('../pages/toertochten/_slug/voorinschrijvingen.vue' /* webpackChunkName: "pages/toertochten/_slug/voorinschrijvingen" */))
const _664d0122 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _b70a32f0,
    name: "auth"
  }, {
    path: "/toertochten",
    component: _4ca8b228,
    name: "toertochten"
  }, {
    path: "/toertochten/create",
    component: _7021dd96,
    name: "toertochten-create"
  }, {
    path: "/club/:slug",
    component: _45fc9914,
    name: "club-slug"
  }, {
    path: "/toertochten/:slug",
    component: _e333213a,
    name: "toertochten-slug"
  }, {
    path: "/club/:slug?/afstandenkaart",
    component: _839e1ffe,
    name: "club-slug-afstandenkaart"
  }, {
    path: "/club/:slug?/deelnemers",
    component: _143fb926,
    name: "club-slug-deelnemers"
  }, {
    path: "/toertochten/:slug/afstandenkaart",
    component: _b9bc7adc,
    name: "toertochten-slug-afstandenkaart"
  }, {
    path: "/toertochten/:slug/dagdeelnemers",
    component: _46226393,
    name: "toertochten-slug-dagdeelnemers"
  }, {
    path: "/toertochten/:slug/deelnemers",
    component: _595a7c37,
    name: "toertochten-slug-deelnemers"
  }, {
    path: "/toertochten/:slug/voorinschrijvingen",
    component: _98c04860,
    name: "toertochten-slug-voorinschrijvingen"
  }, {
    path: "/",
    component: _664d0122,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
