import Vue from 'vue';

const barcodeService = {
  isRandomDagdeelnemerCode(code) {
    return code && code[0] === 'd';
  },
  isDistanceCode(code, tour = null) {
    // For example "t:34952:d:120"
    const parts = code.split(':');

    return parts[0] === 't' && (tour ? +parts[1] === +tour : +parts[1] > 0) && parts[2] === 'd' && +parts[3] > 0;
  },
  isPaidCode(code, tour) {
    // For example "t:34952:p:1"
    const parts = code.split(':');

    return parts[0] === 't' && +parts[1] === tour && parts[2] === 'p';
  },
};

Vue.prototype.$barcode = barcodeService;

export default ({store}, inject) => {
  inject('barcode', barcodeService);
};
