import Vue from 'vue';
import Vuetify from 'vuetify';
import nl from 'vuetify/es5/locale/nl';
import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {
  faBarcodeRead,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faEuroSign,
  faSearch,
  faPlus,
  faMinus,
  faThumbsUp,
  faTimes,
  faEnvelope,
  faInfoCircle,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';

library.add(
  faBarcodeRead,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faEuroSign,
  faSearch,
  faPlus,
  faMinus,
  faThumbsUp,
  faTimes,
  faEnvelope,
  faInfoCircle,
  faWhatsapp,
  faTrash,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

Vue.use(Vuetify, {
  lang: {
    locales: {nl},
    current: 'nl',
  },
  icons: {
    search: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'search'],
      },
    },
    sort: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-up'],
      },
    },
    prev: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-left'],
      },
    },
    next: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-right'],
      },
    },
    dropdown: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'angle-down'],
      },
    },
    info: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'info-circle'],
      },
    },
    clear: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'times'],
      },
    },
  },
  theme: {
    primary: '#2E2D2C',
    secondary: '#00A7E7',
    accent: '#C9DCEA',
    secaccent: '#67737A',
    thirdaccent: '#E73339',
    info: '#00A7E7',
    success: '#00A7E7',
    warning: '#D10A0F',
    error: '#D10A0F',
    greyLight: '#EBEEF2',
    blueLight: '#EBF5F7',
  },
});
