<template>
  <v-toolbar class="elevation-0 hidden-print-only">
    <nuxt-link to="/" class="logo">
      <img alt="NTFU" src="~/assets/images/logo.png" class="hidden-sm-and-down">
      <img alt="NTFU" src="~/assets/images/logo-horizontal.png" class="hidden-md-and-up">
    </nuxt-link>
    <v-spacer/>
    <v-toolbar-items class="body-2">
      <div v-show="showRelease" class="toolbar-item text-xs-right">
        <div class="secaccent--text font-weight-bold">Versie</div>
        <div>{{ release ? release.substring(0, 8) : '' }}</div>
      </div>
      <div v-show="loggedIn && showStatus" class="toolbar-item text-xs-right">
        <div class="secaccent--text font-weight-bold">{{ $nuxt.isOnline ? 'Online' : 'Offline' }}</div>
        <div v-if="syncedScanned === totalScanned">Bijgewerkt</div>
        <div v-else>{{ totalScanned - syncedScanned }} in wachtrij</div>
      </div>
      <v-menu offset-y open-on-hover>
        <template #activator="{on}">
          <div v-if="user.firstName || user.lastName" v-show="showStatus" class="toolbar-item text-xs-right" v-on="on">
            <div class="secaccent--text font-weight-bold">Welkom</div>
            <div v-if="user.firstName">{{ user.firstName }} <span class="hidden-sm-and-down">{{ user.lastName }}</span></div>
            <div v-else class="text-overflow">{{ user.lastName }}</div>
          </div>
        </template>
        <v-list flat>
          <v-list-tile @click="logout">
            <v-list-tile-title>Uitloggen</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <div v-if="!isVolunteer && showStatus" class="toolbar-item px-0">
        <v-btn :to="{name: 'toertochten-create'}" :disabled="!loggedIn" color="secondary" flat nuxt class="px-0 secaccent--text">
          <span class="hidden-sm-and-down px-3">Toertocht aanmaken</span>
          <span class="add-tour">
            <font-awesome-icon :icon="['far', 'plus']" color="white" size="2x"/>
          </span>
        </v-btn>
      </div>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import {mapGetters, mapState} from 'vuex';

export default {
  data() {
    return {
      showStatus: false,
    };
  },
  computed: {
    ...mapState(['release']),
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      user: 'auth/user',
      isVolunteer: 'auth/isVolunteer',
      totalScanned: 'queue/totalScanned',
      syncedScanned: 'queue/syncedScanned',
    }),
    showRelease() {
      return process.client && this.release && location.hostname === 'accept.scanengo.nl';
    },
  },
  mounted() {
    setTimeout(() => (this.showStatus = true), 100);
  },
  methods: {
    async logout() {
      if (this.syncedScanned !== this.totalScanned) {
        if (!confirm('Je hebt nog deelnemers in de wachtrij. Deze worden gewist bij het uitloggen. Weet je zeker dat je wilt uitloggen?')) {
          return;
        }
      }

      await this.$vlf.clear();
      await this.$store.dispatch('auth/logOut');
      await this.$router.push({path: '/'});
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.v-toolbar
  position sticky
  z-index 4
  top 0

  >>> .v-toolbar__content
    height 50px !important
    background white
    padding-right 0

.logo
  position absolute
  top 0
  left 0
  z-index 9

  img
    position relative
    top 10px
    left 15px
    width 100px
    z-index 2

.toolbar-item
  display flex
  flex-direction column
  justify-content center
  padding 0 10px
  color: primary

  &:not(:first-of-type)
    border-left 1px solid accent

.v-btn
  height 100% !important
  min-width 50px !important

.add-tour
  display flex
  align-items center
  justify-content center
  height 50px
  width 50px
  background thirdaccent

.theme--light.v-btn.v-btn--disabled .add-tour
  color white !important
  background alpha(thirdaccent, 0.5)

.v-menu__content
  box-shadow none
  border-top-left-radius 0
  border-top-right-radius 0

.text-overflow
  max-width: 70px
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden

@media (min-width: 768px)
  .logo img
    top 33px
    left 22px
    width 175px

  .text-overflow
    max-width: none

@media (min-width: 768px)
  .v-toolbar
    >>> .v-toolbar__content
      height 90px !important

  .logo
    &::before
      content ''
      position absolute
      width 280px
      height 152px
      background url("/bg-logo.png") center no-repeat
      background-size cover

  .toolbar-item
    &:not(:last-of-type)
      padding 0 16px

  .add-tour
    width 90px
    height 90px

@media (min-width: 960px)
  .logo img
    width 130px
    top 20px
    left 40px

</style>
