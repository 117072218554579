<template>
  <v-app>
    <feedback v-if="loggedIn"/>
    <app-header/>
    <page-title/>
    <v-content>
      <v-container :grid-list-sm="$vuetify.breakpoint.xsOnly.isMounted" grid-list-xl>
        <nuxt/>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import Header from '~/layouts/partials/Header';
import Title from '~/layouts/partials/Title';
import Feedback from '~/layouts/partials/Feedback';

export default {
  components: {
    'app-header': Header,
    'page-title': Title,
    Feedback,
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
    }),
  },
};
</script>
