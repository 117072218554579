import Vue from 'vue';
import Axios from 'axios';

export default ({app, store, redirect}, inject) => {
  const ntfu = new function () {
    const baseUrl = 'https://webservice.ntfu.nl/scanengo/';

    /*

    Available endpoints

    /versienummer
    /whitelist
    /vereniging/{club_id}
    /tochten/{club_id}/{is_vrijwilliger}

    /voorinschrijvingen/{club_id}/{tocht_id}
    /deelnemers/{club_id}/{tocht_id}
    /realtimescan/{club_id}/{tocht_id}/{barcode}/{timestamp}
    /senddeelnemers/{club_id}/{tocht_id}
    /senddeelnemersdag/{club_id}/{tocht_id}

    /eigentocht/{club_id}
    /eigentochten/{club_id}
    /sendeigendeelnemers/{club_id}/{club_tocht_id}

    /sendinstellingen

    /deletedeelnemer/{club_id}/{tocht_id}/{barcode}}
    /deleteeigendeelnemer/{club_id}/{club_tocht_id}/{barcode}

    */

    this.request = async (endpoint, method = 'post', json = null) => {
      try {
        const token = store.state.auth.token;
        if (!token) {
          store.dispatch('auth/logOut');
          redirect('/');
        }

        const instance = Axios.create({
          headers: {Authorization: store.state.auth.token},
        });

        let response;

        if (json) {
          const data = 'data=' + JSON.stringify(json);

          response = await instance[method](baseUrl + endpoint, data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
        } else {
          response = await instance[method](baseUrl + endpoint);
        }

        return response.data;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          store.dispatch('auth/logOut');
          redirect('/');
        } else {
          throw e;
        }
      }
    };

    this.getUser = async () => {
      const data = await this.request('user');

      return {
        firstName: data.voornaam,
        lastName: (data.tussenvoegsel ? data.tussenvoegsel + ' ' : '') + data.achternaam,
        isNtfuMember: data.is_ntfu_lid,
        isVolunteer: data.is_vrijwilliger,
        clubs: data.verenigingen || [],
      };
    };

    this.getWhitelist = async () => {
      return await this.request('whitelist');
    };
    this.getVersion = async () => {
      return await this.request('versienummer');
    };

    this.getClub = async (clubId) => {
      return await this.request('vereniging/' + clubId);
    };

    this.getTours = async (clubId, isVolunteer) => {
      return await this.request('tochten/' + clubId + '/' + (isVolunteer ? 'true' : 'false'));
    };

    this.getPreRegistrations = async (clubId, tourId) => {
      return await this.request('voorinschrijvingen/' + clubId + '/' + tourId);
    };

    this.getParticipants = async (clubId, tourId) => {
      return await this.request('deelnemers/' + clubId + '/' + tourId);
    };

    this.getClubParticipants = async (clubId, tourId) => {
      return await this.request('eigendeelnemers/' + clubId + '/' + tourId);
    };

    this.sendParticipants = async (clubId, tourId, participants) => {
      return await this.request('senddeelnemers/' + clubId + '/' + tourId, 'post', participants);
    };

    this.sendParticipantsDay = (clubId, tourId, participants) => {
      return this.request('senddeelnemersdag/' + clubId + '/' + tourId, 'post', participants);
    };

    this.sendRealtimeScan = (clubId, tourIds, barcode, timestamp) => {
      timestamp = timestamp || app.$datetime.local().toFormat('yyyyMMddHHmmss');

      if (Array.isArray(tourIds)) {
        tourIds = tourIds.join('-');
      }

      return this.request(`realtimescan/${clubId}/${tourIds}/${barcode}/${timestamp}`);
    };

    this.sendDayRegistrations = () => {};

    this.saveClubTour = (clubId, data) => {
      return this.request('eigentocht/' + clubId, 'post', data);
    };

    this.getClubTours = async (clubId) => {
      return await this.request('eigentochten/' + clubId);
    };

    this.sendClubParticipants = async (clubId, tourId, participants) => {
      return await this.request(
        'sendeigendeelnemers/' + clubId + '/' + tourId,
        'post',
        participants,
      );
    };

    this.deleteParticipant = async (clubId, tourId, barcode) => {
      return await this.request(`deletedeelnemer/${clubId}/${tourId}/${barcode}`);
    };

    this.deleteClubParticipant = async (clubId, tourId, barcode) => {
      return await this.request(`deleteeigendeelnemer/${clubId}/${tourId}/${barcode}`);
    };

    this.sendSettings = (clubId, tourId, settings) => {
      return this.request(`sendinstellingen/${clubId}/${tourId}`, 'post', settings);
    };

    this.feedback = (message, tourId, name, email = null) => {
      return this.request('feedback', 'post', {
        ride_id: tourId,
        naam: name,
        email,
        feedback: message,
      });
    };
  }();

  Vue.prototype.$ntfu = ntfu;
  inject('ntfu', ntfu);
};
