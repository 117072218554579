<template>
  <div class="feedback-holder hidden-print-only">
    <v-dialog v-model="dialog" persistent max-width="350">
      <template #activator="{ on }">
        <div class="feedback-button">
          <v-btn color="secondary" v-on="on">Stuur feedback</v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title class="headline">Feedback</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="feedbackMessage"
            label="Feedback"
            outline
            full-width
            hide-details
            class="field"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" class="white--text" text @click="dialog = false">Annuleer</v-btn>
          <v-btn color="secondary" text @click="sendFeedback">Stuur feedback</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      dialog: false,
      feedbackMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      user: 'auth/user',
    }),
  },
  methods: {
    sendFeedback() {
      if (!this.feedbackMessage || this.feedbackMessage.trim().length === 0) {
        return;
      }

      const tourId = parseInt(this.$route.params.slug, 10);
      const {firstName, lastName} = this.loggedIn ? this.user : {};
      const message = this.feedbackMessage;

      this.$nextTick(() =>
        this.$ntfu.feedback(
          message,
          tourId || null,
          [firstName, lastName].filter(a => a).join(' '),
        ),
      );

      this.$nextTick(() =>
        this.$bugsnag.notify(new Error('Feedback bericht'), {
          metaData: {
            feedback: {
              bericht: message,
            },
          },
        }),
      );

      this.dialog = false;
      this.feedbackMessage = '';
    },
  },
};
</script>

<style lang="stylus">
.feedback-holder
  height: 0
  z-index 1

.feedback-button
  position fixed
  left 0.9%
  top 50%
  transform translateX(-50%) translateY(-50%) rotate(-90deg)
</style>
