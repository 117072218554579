import Vue from 'vue';
import * as localForage from 'localforage';

export default async ({app, store, error}, inject) => {
  await app.$vlf.config({storeName: 'ntfu_toertochten'});
  await app.$vlf.setDriver([localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE]);

  const offline = new Vue({
    data() {
      return {
        shouldRefresh: false,
        cacheInMinutes: 10,
      };
    },
    methods: {
      getClub(clubId) {
        return this.proxy('getClub', [clubId]);
      },

      // NTFU tours
      getTours(clubId, isVolunteer) {
        return this.proxy('getTours', [clubId, isVolunteer]);
      },
      getPreRegistrations(tourId) {
        const tour = store.getters['ntfu/tour'](tourId);

        return this.proxy('getPreRegistrations', [tour.clubId, tourId]);
      },
      async getPreRegistration(tourId, barcode) {
        const participants = await this.getPreRegistrations(tourId);

        return participants.find(participant => participant.barcode === parseInt(barcode, 10));
      },
      async getParticipants(tourId) {
        const tour = store.getters['ntfu/tour'](tourId);

        return await this.proxy('getParticipants', [tour.clubId, tourId]);
      },
      async getParticipant(tourId, barcode) {
        const participants = await this.getParticipants(tourId);

        return participants.find(participant => participant.barcode === parseInt(barcode, 10));
      },
      async setTourSettings(clubId, tourId, settings) {
        const key = `getTours_${clubId}`;
        const tours = (await app.$vlf.getItem(key)) || [];

        tours.forEach((tour) => {
          if (tour.nummer === tourId) {
            Object.keys(settings).forEach((setting) => {
              tour.instellingen[setting] = settings[setting];
            });
          }
        });

        app.$vlf.setItem(key, tours);
      },

      // Club tours
      getClubTours(clubId) {
        return this.proxy('getClubTours', [clubId]);
      },
      getClubParticipants(tourId) {
        const tour = store.getters['ntfu/clubTour'](tourId);

        return this.proxy('getClubParticipants', [tour.clubId, tourId]);
      },

      // Internal methods
      async proxy(method, args, errorWhenOffline = true) {
        const key = method + (args ? '_' + args.join('_') : '');
        let value = await app.$vlf.getItem(key);

        if (this.shouldRefresh && this.$nuxt.isOnline) {
          value = null;
        }

        if (!value) {
          if (errorWhenOffline) {
            this.assertOnline();
          }

          if (typeof app.$ntfu[method] !== 'function') {
            throw new TypeError('[offline]: ' + method + ' does not exists.');
          }

          value = await app.$ntfu[method](...(args || []));

          if (value) {
            app.$vlf.setItem(key, value);
          }

          this.shouldRefresh = false;
          this.refreshed(method, args, value);
        }

        return value;
      },
      isOffline() {
        return this.$nuxt.isOffline;
      },
      assertOnline() {
        if (this.isOffline()) {
          error({statusCode: 503});
        }
      },
      refresh(refresh = false) {
        this.shouldRefresh = refresh;

        return this;
      },
      async checkShouldRefresh() {
        if (this.isOffline()) {
          return false;
        }

        const updatedAt = await app.$vlf.getItem('updatedAt');

        if (!updatedAt) {
          return true;
        }

        return updatedAt < Date.now() / 1000 - 60 * this.cacheInMinutes;
      },
      refreshed(method, args, value) {
        if (method === 'getParticipants') {
          store.dispatch('queue/clearProcessed', args[1]);
        } else if (method === 'getClubParticipants') {
          store.dispatch('queue/clearProcessed', args[1] + '_own');
        }
      },
      setUpdatedAt() {
        return app.$vlf.setItem('updatedAt', Date.now() / 1000);
      },
      gc() {
        const user = store.getters['auth/user'];

        if (!store.getters['auth/loggedIn']) {
          this.clear();

          return;
        }

        app.$vlf.keys().then((keys) => {
          keys.forEach((key) => {
            const parts = key.split('_');

            switch (parts[0]) {
              case 'getClub':
              case 'getTours':
              case 'getClubTours':
              case 'getParticipants':
              case 'getPreRegistrations':
                if (!user.clubs || !user.clubs.includes(parseInt(parts[1], 10))) {
                  app.$vlf.removeItem(key);

                  return;
                }
            }

            switch (parts[0]) {
              case 'getParticipants':
              case 'getPreRegistrations':
                const tourIds = store.getters['ntfu/tours'].map(tour => tour.id);

                if (!tourIds.includes(parseInt(parts[2], 10))) {
                  app.$vlf.removeItem(key);
                }
            }
          });
        });
      },
      clear() {
        app.$vlf.clear();
      },
    },
  });

  Vue.prototype.$offline = offline;
  inject('offline', offline);
};
