import Vue from 'vue';

Vue.filter('capitalize', val => val.toUpperCase());

Vue.filter('lowercase', val => val.toLowerCase());

Vue.filter('ucFirst', val => val.charAt(0).toUpperCase() + val.slice(1));

Vue.filter('currency', val =>
  parseFloat(val)
    .toFixed(2)
    .replace('.', ','),
);
