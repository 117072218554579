export const state = () => ({
  release: process.env.RELEASE,
  isMobile: null,
});

export const mutations = {
  setIsMobile(state) {
    state.isMobile =
      typeof window.orientation !== 'undefined' || navigator.userAgent.includes('IEMobile');
  },
};

export const actions = {
  async nuxtServerInit({commit}, context) {
    if (context.route.name === 'auth') {
      context.store.dispatch('auth/saveToken', context.route.query.loginToken);

      context.redirect('/toertochten');
    }

    await context.store.dispatch('auth/initAuth');
  },
  nuxtClientInit({state, commit}, context) {
    const initInterval = setInterval(async () => {
      // Load when required plugins are available
      if (context.app.$offline) {
        clearInterval(initInterval);

        context.store.dispatch('auth/setLoginUrls');

        if (context.store.state.auth.loggedIn) {
          await context.store.dispatch('ntfu/init');
          await context.store.dispatch('queue/init');
        }

        context.store.commit('setIsMobile');
      }
    }, 5);

    // Check for new versions
    if (state.release) {
      context.store.dispatch('version/checkForNewVersion');
    }

    setTimeout(() => {
      if (state.auth.user) {
        context.$bugsnag.user = {
          ...state.auth.user,
        };
      }
    });
  },
};
