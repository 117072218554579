export const state = () => ({
  loginUrl: '',
  loginUrlVolunteer: '',
  loggedIn: false,
  user: {},
  token: null,
});

export const getters = {
  loggedIn(state) {
    return state.loggedIn;
  },
  user(state) {
    return state.user;
  },
  isVolunteer(state) {
    return state.user.isVolunteer;
  },
};

export const mutations = {
  setLoginUrls(state, urls) {
    state.loginUrl = urls.default;
    state.loginUrlVolunteer = urls.volunteer;
  },
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = null;
    state.loggedIn = false;
    state.user = {};
  },
  setUser(state, user) {
    state.user = user;
    state.loggedIn = true;
  },
};

export const actions = {
  async initAuth({state, commit, dispatch}) {
    if (state.token && state.loggedIn) {
      // User data already in store
      return;
    }

    const token = state.token || this.$cookies.get('token');

    if (token) {
      commit('setToken', token);

      try {
        const user = await this.$ntfu.getUser();

        commit('setUser', user);
      } catch (e) {
        if (!e.response || e.response.status === 401) {
          dispatch('logOut');
        }

        console.error(e);
      }
    }
  },
  setLoginUrls({commit}, clientUrl) {
    const target = `${clientUrl || window.location.origin}/auth`;

    commit('setLoginUrls', {
      default: `https://www.ntfu.nl/home/login?requesturl=${target}`,
      volunteer: `https://www.ntfu.nl/home/login/vrijwilliger?requesturl=${target}`,
    });
  },
  saveToken({commit}, token) {
    if (token) {
      commit('setToken', token);
      this.$cookies.set('token', token, {
        maxAge: 3600 * 24,
        sameSite: 'none',
        secure: true,
      });
    }
  },
  logOut({commit}) {
    commit('clearToken');
    this.$cookies.remove('token');
  },
};
