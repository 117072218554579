// Method routeOption copied from @nuxt/auth-module
const routeOption = (route, key, value) => {
  return route.matched.some((m) => {
    if (process.browser) {
      // Browser
      return Object.values(m.components).some(
        component => component.options && component.options[key] === value,
      );
    } else {
      // SSR
      return Object.values(m.components).some(component =>
        Object.values(component._Ctor).some(ctor => ctor.options && ctor.options[key] === value),
      );
    }
  });
};

export default (ctx) => {
  // Disable middleware if options: { auth: false } is set on the route
  if (routeOption(ctx.route, 'auth', false)) {
    return;
  }

  if (!ctx.store.state.auth.loggedIn || !ctx.store.state.auth.token) {
    return ctx.redirect('/');
  }
};
