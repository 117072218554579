export const actions = {
  checkForNewVersion({rootState}) {
    const interval = setInterval(async () => {
      if (this.$offline.isOffline()) {
        return;
      }

      // Most browsers don't allow network requests when page is inactive
      if (document.visibilityState === 'hidden') {
        return;
      }

      let data;

      try {
        const response = await this.$axios.get('/release-name.txt');

        data = response.data;
      } catch (e) {
        this.$bugsnag.leaveBreadcrumb(
          'Server version not available (' + (e?.response?.status || 0) + ')',
        );
        this.$bugsnag.notify(e);

        return;
      }
      const serverRelease = data.trim();

      if (serverRelease && rootState.release !== serverRelease) {
        this.$bugsnag.leaveBreadcrumb('New version available: `' + JSON.stringify(data) + '`');

        this.$toast.info(
          'Er is een nieuwe versie beschikbaar. Vernieuw de pagina om deze te activeren.',
          {
            action: [
              {
                text: 'Vernieuwen',
                onClick: () => {
                  window.location.reload();
                },
              },
              {
                text: 'Sluiten',
                onClick: () => {
                  this.$toast.clear();
                },
              },
            ],
          },
        );

        // Stop polling
        clearInterval(interval);
      }
    }, 60000 * 15); // every 15 minutes
  },
};
