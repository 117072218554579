<template>
  <v-app>
    <app-header/>
    <page-title/>
    <v-content>
      <nuxt/>
    </v-content>
  </v-app>
</template>

<script>
import Header from '~/layouts/partials/Header';
import Title from '~/layouts/partials/Title';

export default {
  components: {
    'app-header': Header,
    'page-title': Title,
  },
};
</script>

<style lang="stylus" scoped>
  @import '~assets/style/colors'

  main
    position relative
    background url("/bg-auth.jpg") center no-repeat
    background-size cover
    margin-top -50px
    width 100%
    height 100vh

    &::before
      content ''
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      height 100vh

  @media (min-width: 768px)

    main
      margin-top -90px

</style>
