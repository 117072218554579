<template>
  <div class="pt-3">
    <template v-if="error.statusCode === 404">
      <p>
        De pagina die je zoekt bestaat niet meer of heeft nooit bestaan.<br>
        Je kan je weg vervolgen door terug te keren naar de <nuxt-link to="/">homepage</nuxt-link> of via het menu
        naar de juiste pagina te navigeren.
      </p>
    </template>
    <template v-else-if="error.statusCode === 503">
      <p>
        De huidige pagina kan niet geladen worden omdat je momenteel geen verbinding hebt.<br>
        Ga online om opnieuw te proberen, of ga terug naar de vorige pagina.
      </p>
    </template>
    <template v-else>
      <p>
        De huidige pagina kan niet geladen worden omdat er een fout is opgetreden.<br>
        Blijft deze fout zich voordoen neem dan contact met ons op via info@ntfu.nl.
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return {
      title: (() => {
        return this.title;
      })(),
    };
  },
  computed: {
    title() {
      if (this.$nuxt.isOffline) {
        return 'Offline';
      } else if (this.error.statusCode === 503) {
        return 'Tijdelijk niet bereikbaar';
      } else if (this.error.statusCode === 404) {
        return 'Deze pagina bestaat niet';
      }
      return 'Er is een fout opgetreden';
    },
  },
  mounted() {
    this.$store.commit('page/setMeta', {
      title: this.title,
    });
  },
};
</script>
