<template>
  <section class="page-title">
    <v-img :src="require('~/assets/images/bg-header-medium.jpg')"
           :srcset="`${require('~/assets/images/bg-header-small.jpg')} 1200w,
           ${require('~/assets/images/bg-header-medium.jpg')} 1440w,
           ${require('~/assets/images/bg-header-large.jpg')} 2880w
           `"
    >
      <v-container d-flex fill-height mt-md-5>
        <v-layout row wrap align-end justify-space-between>
          <v-flex>
            <div class="body-2 secondary--text text-uppercase">{{ page.category }}</div>
            <h1>{{ page.title }}</h1>
          </v-flex>
          <v-spacer/>
          <v-flex text-md-right>
            <h2 class="white--text">{{ page.location }}</h2>
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
  </section>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: mapState({
    page: state => state.page.meta,
  }),
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.page-title
  position relative
  width 100%
  min-height: 150px

  .v-image
    height: 150px
    >>> .v-image__image--cover
      background-position center 20% !important

  &::before
    content ''
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    min-height 150px

  .container
    position relative
    z-index 2

  h2
    font-size 1.75rem !important
    opacity .5

@media (min-width: 768px)
  .page-title
    height 200px

    .v-image
      height: 200px

    &::before
      height 200px

@media print
  .page-title
    min-height 0
    height auto
    background none

    &::before
      content none

  .white--text
    color black !important
</style>
